import React from 'react';

import { useNavigation, useRevalidator } from 'react-router-dom';

import LinearProgress from '@mui/material/LinearProgress';

import { makeStyles } from 'tss-react/mui';

import { Environment } from 'ha/config';

import { PageLayoutProps } from '../types';

const useStyles = makeStyles()(() => ({
  root: {
    position: 'fixed',
    width: '100%',
    // 1300 is the z-index for the Google Map on search page
    // and we want this progress bar to be above that.
    zIndex: 1301,
  },
}));

export const PageProgressBar: React.FC<{
  state: PageLayoutProps['hasProgressBar'];
}> = ({ state }) => {
  const navigation = useNavigation();
  const revalidator = useRevalidator();

  const { classes } = useStyles();

  if (state === true) {
    return (
      <div
        className={classes.root}
        data-test-locator="Page/loading"
        data-progress-indicator="explicit"
      >
        <LinearProgress />
      </div>
    );
  }

  const isReactRouterLoading =
    navigation.state === 'loading' ||
    navigation.state === 'submitting' ||
    revalidator.state === 'loading';

  if (isReactRouterLoading) {
    if (state === 'auto') {
      return (
        <div
          className={classes.root}
          data-test-locator="Page/loading"
          data-progress-indicator="router"
        >
          <LinearProgress />
        </div>
      );
    }

    // used to indicate react router loading states that are not explicitly tracked with `Page`' `hasProgressBar` .. only in development
    if (process.env.NODE_ENV === Environment.DEVELOPMENT) {
      return (
        <div
          className={classes.root}
          data-test-locator="Page/loading"
          data-progress-indicator="unhandled"
        >
          <LinearProgress color="error" />
        </div>
      );
    }
  }

  // placeholder for to use for test assertions
  return <div data-test-locator="Page/idle" hidden />;
};
